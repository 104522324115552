<template>
     <div class="body" v-scroll="onScroll">
          <navbar class="scrollsnap"></navbar>

          <div class="container scrollsnap headcarousel ">
               <hooper class="slider " :settings="head_hooper_settings" ref="headCarousel">
                    <slide v-for="(item, index) in headerSlides" :key="index">
                         <div class="columns  is-tablet reverse2">
                              <div class="column">
                                   <div class="text">
                                        <h1 v-html="item.heading"></h1>

                                        <div class="mt-30">
                                             <p v-html="item.content" style="font-size:23px;font-weight:medium"></p>

                                             <router-link :to="{ name: item.link }" class="mt-2" style="display:block">Know More</router-link>
                                        </div>
                                   </div>
                              </div>
                              <div class="column ">
                                   <div class="image">
                                        <img :src="require(`@/assets/images/${item.image}`)" alt="" />
                                   </div>
                              </div>
                         </div>
                    </slide>
                    <hooper-pagination slot="hooper-addons" class="is-hidden-desktop " style="margin-top:30px;"></hooper-pagination>
               </hooper>

               <div class="hoopernavigation is-hidden-touch">
                    <button class="prev visible" @click="$refs.headCarousel.slidePrev()">
                         <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                              <g id="Group_415" data-name="Group 415" transform="translate(1115.74 145.803) rotate(180)">
                                   <line
                                        id="Line_40"
                                        data-name="Line 40"
                                        x2="42"
                                        transform="translate(1073 136)"
                                        fill="none"
                                        stroke="#89c184"
                                        stroke-width="1"
                                   />
                                   <path
                                        id="Path_21"
                                        data-name="Path 21"
                                        d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                        transform="translate(2)"
                                        fill="none"
                                        stroke="#89c184"
                                        stroke-width="1"
                                   />
                              </g>
                         </svg>
                    </button>
                    <button class="next visible" @click="$refs.headCarousel.slideNext()">
                         <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                              <g id="Group_415" data-name="Group 415" transform="translate(-1073 -126.299)">
                                   <line
                                        id="Line_40"
                                        data-name="Line 40"
                                        x2="42"
                                        transform="translate(1073 136)"
                                        fill="none"
                                        stroke="#89c184"
                                        stroke-width="1"
                                   />
                                   <path
                                        id="Path_21"
                                        data-name="Path 21"
                                        d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                        transform="translate(2)"
                                        fill="none"
                                        stroke="#89c184"
                                        stroke-width="1"
                                   />
                              </g>
                         </svg>
                    </button>
               </div>
          </div>

          <div class="container  scrollsnap section2">
               <div class="para ">
                    We build seamless technological solutions that are quality assured, result oriented and customised as per your business operations
                    and future growth. Since 1994, we have underlined every project with quality and excellence thus enriching our experience and
                    growing our expertise in the area of Information Technology Management, IT Infrastructure Solution and IT Engineering.
                    <router-link :to="{ name: 'ABOUT' }" class="p nobreak primary-color ">Know More</router-link>
               </div>
          </div>

          <div class="section3 scrollsnap">
               <div class="container">
                    <div class="section-title">Solutions</div>

                    <div class="section-header">
                         Designed with Resilience and Intuition.
                         <router-link :to="{ name: 'SERVICES' }" class="p tertiary-color">Know More</router-link>
                    </div>
                    <div class="columns">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'AIOT' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/aiot2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Artificial Intelligence Of Things (AIoT)</div>
                                                  <p>
                                                       The IT landscape is being revolutionised by big data, IoT, IIoT, AI, cloud, automation and
                                                       machine learning. Enterprise business models are adapting to these changes with a better
                                                       understanding of these technologies, agile design principles, strategic technological and
                                                       infrastructural approaches.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'CC' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/cc2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Cloud Computing Services</div>
                                                  <p>
                                                       CTL comes in as your committed partner for cloud computing services when your business demands
                                                       are growing and your  IT capacity for innovation is not able to keep up.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>

                    <div class="columns">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'ITES' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ites2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">ITeS And Networking Solutions</div>
                                                  <p>
                                                       An efficient ITeS system is the bedrock for growth intensive enterprises. CTL excels with its
                                                       vast experience in ITeS and IBMS networking, enabling it to design and develop vendor-agnostic
                                                       solutions.
                                                  </p>
                                             </div>
                                        </div></router-link
                                   >
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'IBMS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ibms2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Integrated Building Management System</div>
                                                  <p>
                                                       CTL designs IBMS solutions for corporates, commercial buildings, hotels, hospitals, industries,
                                                       residences, institutes, etc. These systems are commissioned to give economy of operations and
                                                       to achieve maximum utilisation of resources
                                                  </p>
                                             </div>
                                        </div></router-link
                                   >
                              </div>
                         </div>
                    </div>
                    <div class="columns">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'ESS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ess2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Electronic Security Solutions</div>
                                                  <p>
                                                       As a world class provider of integrated security solutions, CTL ESS services offers total
                                                       electronic security solutions bringing the latest, reliable and proven technologies
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'AVS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/avs2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Audio Visual Systems</div>
                                                  <p>
                                                       CTL  has extensive experience with customised audio visual systems for commercial, retail,
                                                       hospitality and corporate environments.
                                                  </p>
                                             </div>
                                        </div></router-link
                                   >
                              </div>
                         </div>
                    </div>
                    <div class="columns">
                         <div class="column is-half">
                              <div class="service-item">
                                   <a href="https://ctldatacenter.web.app/" target="_blank">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/dcs2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Data Centre Solutions</div>
                                                  <p>
                                                       Since 1994, CTL has been at the forefront of building compliant on premise Data Centers. 
                                                       Customised to suit your company’s requirements and accommodate your future growth, the CTL Data
                                                       Centre solutions is a comprehensive formula to safeguard your business.
                                                  </p>
                                             </div>
                                        </div>
                                   </a>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'DAS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/das2.png" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title">Distributed Antenna Systems</div>
                                                  <p>
                                                       CTLs Distributed Antenna System (DAS) design meets cabling innovation with operator-grade,
                                                       enterprise-ready solutions. Our DAS portfolio of in-building wireless infrastructure solutions
                                                       offers built-in RF intelligence and flexible architectures to fit every facility, large or
                                                       small.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class=" section4 scrollsnap">
               <div class="container">
                    <div class="section-title">Industries</div>
                    <div class="columns  p-0" style="margin-bottom:50px">
                         <div class="column ">
                              <div class="section-header">A quick look at our hallmark contributions to major industries</div>
                         </div>
                    </div>
               </div>
               <div>
                    <div class="container ">
                         <div class="columns">
                              <div class="column">
                                   <div class="industry">
                                        <div class="slide-container">
                                             <div class="image-container">
                                                  <img :src="require(`@/assets/images/${industries[0].image}`)" />
                                                  <span class="button-container">
                                                       <router-link
                                                            class="viewprojectsbtn"
                                                            :to="{ name: industries[0].linkName, params: { scroll: '#' + industries[0].hash } }"
                                                            ><button class="button is-secondary ">View Projects</button></router-link
                                                       ></span
                                                  >
                                             </div>
                                             <h2 class="mt-20">
                                                  {{ industries[0].heading }}
                                             </h2>
                                             <p class="mt-20">{{ industries[0].content }}</p>
                                        </div>
                                   </div>
                              </div>
                              <div class="column">
                                   <div class="industry">
                                        <div class="slide-container">
                                             <div class="image-container">
                                                  <img :src="require(`@/assets/images/${industries[1].image}`)" />
                                                  <span class="button-container">
                                                       <router-link
                                                            class="viewprojectsbtn"
                                                            :to="{ name: industries[1].linkName, params: { scroll: '#' + industries[1].hash } }"
                                                            ><button class="button is-secondary ">View Projects</button></router-link
                                                       ></span
                                                  >
                                             </div>
                                             <h2 class="mt-20">
                                                  {{ industries[1].heading }}
                                             </h2>
                                             <p class="mt-20">{{ industries[1].content }}</p>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                              <div class="column">
                                   <div class="industry">
                                        <div class="slide-container">
                                             <div class="image-container">
                                                  <img :src="require(`@/assets/images/${industries[2].image}`)" />
                                                  <span class="button-container">
                                                       <router-link
                                                            class="viewprojectsbtn"
                                                            :to="{ name: industries[2].linkName, params: { scroll: '#' + industries[2].hash } }"
                                                            ><button class="button is-secondary ">View Projects</button></router-link
                                                       ></span
                                                  >
                                             </div>
                                             <h2 class="mt-20">
                                                  {{ industries[2].heading }}
                                             </h2>
                                             <p class="mt-20">{{ industries[2].content }}</p>
                                        </div>
                                   </div>
                              </div>
                              <div class="column">
                                   <div class="industry">
                                        <div class="slide-container">
                                             <div class="image-container">
                                                  <img :src="require(`@/assets/images/${industries[3].image}`)" />
                                                  <span class="button-container">
                                                       <router-link
                                                            class="viewprojectsbtn"
                                                            :to="{ name: industries[3].linkName, params: { scroll: '#' + industries[3].hash } }"
                                                            ><button class="button is-secondary ">View Projects</button></router-link
                                                       ></span
                                                  >
                                             </div>
                                             <h2 class="mt-20">
                                                  {{ industries[3].heading }}
                                             </h2>
                                             <p class="mt-20">{{ industries[3].content }}</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="container   scrollsnap section5">
               <div class="section-title">Testimonials</div>
               <div class="section-header">Your partner for innovative efficiency.</div>

               <div class="columns">
                    <div class="column">
                         <div class="subheading ">
                              Our services are designed to contain growth and innovation for our clients and their words serve as the conviction that
                              propels us towards excellence.
                         </div>
                    </div>
               </div>

               <div class="testimonials  is-hidden-mobile ">
                    <div class="columns">
                         <div class="testimonial  column">
                              <p class="">
                                   I would like to thank CTL for being one of our most professional and dependable service providers. CTL is providing
                                   us very good service for over many years. No matter what the project, CTL has always been there to investigate and
                                   provide quality products, solutions and services.
                              </p>
                              <div class="name">
                                   Mr. Jayachandran C P,
                              </div>
                              <div class="position">DP World Mumbai</div>
                         </div>

                         <div class="testimonial column">
                              <p class="">
                                   CTL has designed, executed and completed the Disaster Recovery centre (DRC) Infrastructure for ERP at HOCL,
                                   Rasayani, Raigad, Maharashtra. They have finished the work satisfactorily and within the specified time.
                              </p>
                              <div class="name">
                                   M R Sathe,
                              </div>
                              <div class="position">Chief Manager - HOCL</div>
                         </div>
                    </div>
                    <div class="columns">
                         <div class="testimonial column ">
                              <p class="">
                                   We have a received a very good engineering and delivery support with extreme efficiency from CTL for Information
                                   and Communication Technology (ICT) and Digital Security Systems for both the Lulu Hyatt Regency Project Thrissur
                                   and Lulu Grand Hyatt and Convention Centre Bolgatty, Kochi and the projects were delivered to Grand Hyatt
                                   successfully on time with proper handing over documentation.
                              </p>
                              <div class="name">
                                   Paul K Olakkengil,
                              </div>
                              <div class="position">GM - MEP LULU</div>
                         </div>

                         <div class="testimonial column ">
                              <p class="">
                                   We had done business with various vendors in our area until we became acquainted with CTL. We were very pleased
                                   with their service , their expertise, their professionalism on the job and their ability to meet the schedule.
                              </p>
                              <div class="name">
                                   Mr. Lawrence,
                              </div>
                              <div class="position">Hotel Residency - Mumbai Fort</div>
                         </div>
                    </div>
                    <div class="columns">
                         <div class="testimonial column">
                              <p class="">
                                   CTL has successfully completed the work of Design, Construction and Commissioning of Data center at Cochin
                                   International Airport Ltd.
                              </p>
                              <div class="name">
                                   Santhosh S,
                              </div>
                              <div class="position">Manager - CIAL</div>
                         </div>

                         <div class="testimonial column ">
                              <p class="">
                                   CTL have been entrusted with the work of IT Infrastructure including Networking (Data and Voice) installations at
                                   LULU International Shopping Mall Pvt Ltd, Kochi and they have completed all the works satisfactorily.
                              </p>
                              <div class="name">
                                   Mr. Babu Varghese,
                              </div>
                              <div class="position">Project Director - LULU</div>
                         </div>
                    </div>

                    <div class="columns">
                         <div class="testimonial  column">
                              <p class="">
                                   CTL has successfully completed the work of Design, Construction and Commissioning of Data centre at HAL Bangalore
                                   Complex, helicopter Division, Bangalore.
                              </p>
                              <div class="name">
                                   Project Manager,
                              </div>
                              <div class="position">TCS</div>
                         </div>

                         <div class="testimonial  column">
                              <p class="">
                                   We have successfully completed the construction of our corporate office and have moved in. It would not have been
                                   possible without your wonderful support and guidance. We acknowledge your concerted effort and express our profound
                                   thanks.
                              </p>
                              <div class="name">
                                   Kochouseph Chittilappilly,
                              </div>
                              <div class="position">MD - VGuard Industries</div>
                         </div>
                    </div>
               </div>

               <div class="testimonials is-hidden-desktop">
                    <hooper class="slider " :settings="testimonials_hooper_settings" ref="headCarousel">
                         <slide>
                              <div class="testimonial  ">
                                   <p class="">
                                        I would like to thank CTL for being one of our most professional and dependable service providers. CTL is
                                        providing us very good service for over many years. No matter what the project, CTL has always been there to
                                        investigate and provide quality products, solutions and services.
                                   </p>
                                   <div class="name">
                                        Mr. Jayachandran C P,
                                   </div>
                                   <div class="position">DP World Mumbai</div>
                              </div>
                         </slide>

                         <slide>
                              <div class="testimonial ">
                                   <p class="">
                                        CTL has designed, executed and completed the Disaster Recovery centre (DRC) Infrastructure for ERP at HOCL,
                                        Rasayani, Raigad, Maharashtra. They have finished the work satisfactorily and within the specified time.
                                   </p>
                                   <div class="name">
                                        M R Sathe,
                                   </div>
                                   <div class="position">Chief Manager - HOCL</div>
                              </div>
                         </slide>

                         <slide>
                              <div class="testimonial  ">
                                   <p class="">
                                        We have a received a very good engineering and delivery support with extreme efficiency from CTL for
                                        Information and Communication Technology (ICT) and Digital Security Systems for both the Lulu Hyatt Regency
                                        Project Thrissur and Lulu Grand Hyatt and Convention Centre Bolgatty, Kochi and the projects were delivered to
                                        Grand Hyatt successfully on time with proper handing over documentation.
                                   </p>
                                   <div class="name">
                                        Paul K Olakkengil,
                                   </div>
                                   <div class="position">GM - MEP LULU</div>
                              </div>
                         </slide>
                         <slide>
                              <div class="testimonial ">
                                   <p class="">
                                        We had done business with various vendors in our area until we became acquainted with CTL. We were very
                                        pleased with their service , their expertise, their professionalism on the job and their ability to meet the
                                        schedule.
                                   </p>
                                   <div class="name">
                                        Mr. Lawrence,
                                   </div>
                                   <div class="position">Hotel Residency - Mumbai Fort</div>
                              </div>
                         </slide>
                         <slide>
                              <div class="testimonial ">
                                   <p class="">
                                        CTL has successfully completed the work of Design, Construction and Commissioning of Data center at Cochin
                                        International Airport Ltd.
                                   </p>
                                   <div class="name">
                                        Santhosh S,
                                   </div>
                                   <div class="position">Manager - CIAL</div>
                              </div>
                         </slide>
                         <slide>
                              <div class="testimonial  ">
                                   <p class="">
                                        CTL have been entrusted with the work of IT Infrastructure including Networking (Data and Voice) installations
                                        at LULU International Shopping Mall Pvt Ltd, Kochi and they have completed all the works satisfactorily.
                                   </p>
                                   <div class="name">
                                        Mr. Babu Varghese,
                                   </div>
                                   <div class="position">Project Director - LULU</div>
                              </div>
                         </slide>
                         <slide>
                              <div class="testimonial  ">
                                   <p class="">
                                        CTL has successfully completed the work of Design, Construction and Commissioning of Data centre at HAL
                                        Bangalore Complex, helicopter Division, Bangalore.
                                   </p>
                                   <div class="name">
                                        Project Manager,
                                   </div>
                                   <div class="position">TCS</div>
                              </div>
                         </slide>
                         <slide>
                              <div class="testimonial  ">
                                   <p class="">
                                        We have successfully completed the construction of our corporate office and have moved in. It would not have
                                        been possible without your wonderful support and guidance. We acknowledge your concerted effort and express
                                        our profound thanks.
                                   </p>
                                   <div class="name">
                                        Kochouseph Chittilappilly,
                                   </div>
                                   <div class="position">MD - VGuard Industries</div>
                              </div>
                         </slide>
                         <hooper-pagination slot="hooper-addons" class="is-hidden-desktop " style="margin-top:30px;"></hooper-pagination>
                    </hooper>
               </div>
          </div>

          <div class="container scrollsnap  section6">
               <div>
                    <div class="columns">
                         <div class="column is-7 ">
                              <div class="certifications">
                                   <div class="section-title">Certifications and Partners</div>
                                   <div class="section-header">Partnering with the best to achieve more</div>
                                   <p>
                                        We have joined forces with the best in the industry to ensure superior project quality and assurance. Our
                                        global certifications give us the singular edge to inculcate the habit of providing the highest quality of
                                        service that is updated regularly through industry event representations and consistent learning.
                                        <br />
                                        <router-link :to="{ name: 'CERTIFICATION' }"> View All</router-link>
                                   </p>
                              </div>
                         </div>
                         <div class="column is-5" style="padding:10px">
                              <div class="columns is-mobile">
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert1.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert2.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert3.png" alt="" />
                                   </div>
                              </div>
                              <div class="columns is-mobile">
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert4.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert5.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert6.png" alt="" />
                                   </div>
                              </div>
                              <div class="columns is-mobile">
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert7.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert8.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert9.png" alt="" />
                                   </div>
                              </div>
                              <div class="columns is-mobile">
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert10.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert11.png" alt="" />
                                   </div>
                                   <div class=" column">
                                        <img src="@/assets/images/certifications/cert12.png" alt="" />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class=" container section4_1 scrollsnap">
               <div class="columns news-container">
                    <div class="column is-half">
                         <div class="news item">
                              <router-link :to="{ name: 'COMINGSOON' }" target="_blank">
                                   <img src="@/assets/images/industries_news_light_2.png" alt="" />
                                   <div class="overlay">
                                        <div class="overlay-text">
                                             <div class="title">
                                                  Industry News

                                                  <span class="icon"
                                                       ><svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="42.74"
                                                            height="19.504"
                                                            viewBox="0 0 42.74 19.504"
                                                       >
                                                            <g id="Group_317" data-name="Group 317" transform="translate(-1073 -126.299)">
                                                                 <line
                                                                      id="Line_40"
                                                                      data-name="Line 40"
                                                                      x2="42"
                                                                      transform="translate(1073 136)"
                                                                      fill="none"
                                                                      stroke="#fff"
                                                                      stroke-width="1"
                                                                 />
                                                                 <path
                                                                      id="Path_21"
                                                                      data-name="Path 21"
                                                                      d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                                      transform="translate(2)"
                                                                      fill="none"
                                                                      stroke="#fff"
                                                                      stroke-width="1"
                                                                 />
                                                            </g>
                                                       </svg>
                                                  </span>
                                             </div>
                                        </div>
                                   </div>
                              </router-link>
                         </div>
                    </div>

                    <div class="column is-half ">
                         <div class="whatsnew item">
                              <img src="@/assets/images/industries_news_light_1.png" alt="" />
                              <div class="overlay">
                                   <div class="overlay-text">
                                        <div class="title">
                                             Whats New At CTL

                                             <span class="icon"
                                                  ><svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                                       <g id="Group_317" data-name="Group 317" transform="translate(-1073 -126.299)">
                                                            <line
                                                                 id="Line_40"
                                                                 data-name="Line 40"
                                                                 x2="42"
                                                                 transform="translate(1073 136)"
                                                                 fill="none"
                                                                 stroke="#fff"
                                                                 stroke-width="1"
                                                            />
                                                            <path
                                                                 id="Path_21"
                                                                 data-name="Path 21"
                                                                 d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                                 transform="translate(2)"
                                                                 fill="none"
                                                                 stroke="#fff"
                                                                 stroke-width="1"
                                                            />
                                                       </g>
                                                  </svg>
                                             </span>
                                        </div>

                                        <div class="content">
                                             <div class="date">January 2021</div>
                                             <p>
                                                  CTL is selected to design and develop the  Central Control Room Data Center for the Kerala Govt Road
                                                  Safety Project. Along with this CTL also obtains another Government project - Supply and
                                                  Installation of Video Surveillance System at New District Jail and Premises, at Malampuzha Palakkad.
                                             </p>
                                             <div class="date">December 2020</div>
                                             <p>
                                                  CTL is selected to execute the Nippon Infra Q1 Mall for its Converged IT infra solution.
                                             </p>
                                             <div class="date">November 2020</div>
                                             <p>
                                                  CTL signed an agreement with Nissan Motors to provide the IBMS comprehensive  service support.
                                             </p>
                                             <div class="date">October 2020</div>
                                             <p>
                                                  CTL got awarded SITC of  Huwei FTTH active components at Taj Gateway Banasurasagar, Wayanad.
                                             </p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <footerComp class="scrollsnap"></footerComp>
     </div>
</template>

<script>
     import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
     import "hooper/dist/hooper.css";

     import footerComp from "../components/footer";
     import navbar from "../components/nav";

     export default {
          data() {
               return {
                    isFixed: false,

                    head_hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         itemsToShow: 1,
                         hoverPause: false,
                         playSpeed: 7000,
                         autoPlay: true,
                         infiniteScroll: true,
                         breakpoints: {
                              800: {
                                   vertical: false,
                              },
                              1000: {
                                   vertical: false,
                              },
                         },
                    },
                    industries_hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,

                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 2.7,
                                   itemsToSlide: 2,
                              },
                         },
                    },
                    testimonials_hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         itemsToShow: 1,
                         hoverPause: false,
                         playSpeed: 7000,
                         autoPlay: true,
                         infiniteScroll: true,
                         breakpoints: {
                              800: {
                                   vertical: false,
                              },
                              1000: {
                                   vertical: false,
                              },
                         },
                    },
                    hooper_industries: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
                    hooper_testimonials: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
                    sliderOptions: {},
                    selected: 0,
                    headerSlides: [
                         {
                              heading: "Efficiency and Excellence are the same.",
                              content: "We support your pursuit for technology driven growth with powerfully designed IT infrastructure",
                              image: "services/ibms.png",
                              link: "SERVICES",
                         },

                         // {
                         //      heading: 'TAJ Wayanad: <br> An esteemed New Collaboration.',
                         //      content: 'A challenging new project designed with cutting edge infrastructure technology for Hospitality.',
                         //      image: 'home_carousel_image_2.png',
                         // },

                         {
                              heading: "The combined power of IoT & AI.",
                              content: "Mobilising innovation with intelligent cloud and intelligent edge to build resilient business solutions.",
                              image: "services/aiot.png",
                              link: "SERVICES",
                         },

                         {
                              heading: "Certified by major OEMs and Global standards.",
                              content: "Secure and Scalable cloud computing solutions designed to accommodate the future of your business growth.",
                              image: "services/ess.png",
                              link: "CERTIFICATION",
                         },

                         {
                              heading: "The future looks Hybrid.",
                              content: "Expanding on premise IT infrastructure with Hybrid cloud computing.",
                              image: "services/cc.png",
                              link: "SERVICES",
                         },
                    ],

                    industries: [
                         {
                              heading: "Hospitality",
                              content:
                                   "CTL collaborates with you for an authenticated and customised technological solution that elevates your brand experience.   ",
                              image: "home/hospitality-home.jpg",
                              linkName: "PROJECTS",
                              hash: "hospitality",
                         },
                         {
                              heading: "Hotels & Conventions ",
                              content:
                                   "CTL brings seamless automation that equips business to serve their clients in ways that are impressive and unobtrusive",
                              image: "home/hotels-home.jpg",
                              linkName: "PROJECTS",
                              hash: "hotels",
                         },
                         {
                              heading: "Healthcare ",
                              content: "CTL has contributed immensely to the technological transformation in the Healthcare sector. ",
                              image: "home/health-care-home.jpg",
                              linkName: "PROJECTS",
                              hash: "healthcare",
                         },
                         {
                              heading: "Finance & Bank",
                              content: "The CTL promise of safe, dependable and steadfast systems that help your customers choose you.  ",
                              image: "home/finance-home.jpg",
                              linkName: "PROJECTS",
                              hash: "finance",
                         },
                    ],
               };
          },
          components: {
               Hooper,
               Slide,
               footerComp,
               navbar,
               HooperPagination,
          },
          methods: {
               hooperSlide_industries(data) {
                    this.hooper_industries.slideNo = data.currentSlide;
                    console.log(this.hooper_industries.slideNo, this.industries.length - 1);
                    if (this.hooper_industries.slideNo > 0) {
                         this.hooper_industries.isPrevBtnVisible = true;
                    } else {
                         this.hooper_industries.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper_industries.slideNo == this.industries.length - 1) {
                              this.hooper_industries.isNextBtnVisible = false;
                         } else {
                              this.hooper_industries.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper_industries.slideNo > 1.2) {
                              this.hooper_industries.isNextBtnVisible = false;
                         } else {
                              this.hooper_industries.isNextBtnVisible = true;
                         }
                    }
               },

               hooperSlide_testimonials(data) {
                    this.hooper_testimonials.slideNo = data.currentSlide;
                    console.log("hi", this.hooper_testimonials.slideNo);
                    if (this.hooper_testimonials.slideNo > 0) {
                         this.hooper_testimonials.isPrevBtnVisible = true;
                    } else {
                         this.hooper_testimonials.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper_testimonials.slideNo == 3) {
                              this.hooper_testimonials.isNextBtnVisible = false;
                         } else {
                              this.hooper_testimonials.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper_testimonials.slideNo == 2) {
                              this.hooper_testimonials.isNextBtnVisible = false;
                         } else {
                              this.hooper_testimonials.isNextBtnVisible = true;
                         }
                    }
               },
               hooperPrev(x) {
                    this.$refs[x].slidePrev();
               },
               hooperNext(x) {
                    this.$refs[x].slideNext();
               },

               onScroll(e, position) {
                    console.log();
               },
          },

          mounted() {
               // setInterval(() => {
               //      this.selected = (this.selected + 1) % this.headerSlides.length;
               // }, 5000);
               // let x = document.getElementsByClassName('scrollsnap');
               // x.forEach((element) => {});
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";

     .body {
          // scroll-snap-type: y mandatory;
          // scroll-padding-top: 105px;
          // scroll-padding-bottom: 105px;
          // // overflow-y: hidden;
          // height: 100vh;
     }
     .scrollsnap {
          // scroll-snap-align: start;
     }

     .headcarousel {
          position: relative;
          .hoopernavigation {
               padding-top: 10px;
               width: 500px;
               padding-left: 0;
               text-align: left;
               position: absolute;
               bottom: -50px;
               height: 50px;
               z-index: 100;
               background-color: rgba(255, 255, 255, 0.9);
               button {
                    width: 70px;
                    margin-right: 10px;
               }

               @media (max-width: 1023px) {
                    padding-top: 0;
                    width: auto;
                    padding-left: 0;
                    text-align: left;
                    position: relative;
                    bottom: 0;
                    text-align: right;
                    height: auto;
               }
          }

          .slider {
               margin-bottom: 20px;
               padding-bottom: 50px;
               // border:1px solid red;
               // height: 600px !important;
               @media (max-width: 1023px) {
                    height: auto !important;
                    padding-bottom: 50px;
                    margin-bottom: 10px;
               }
          }
          padding-top: 0px;
          @media (max-width: 1023px) {
               padding-top: 2px !important;
          }
          .text {
               height: 100%;
               padding-right: 30px;
               @media (max-width: 1023px) {
                    padding-right: 0;
                    padding-top: 15px;
               }

               display: flex;
               flex-direction: column;
               justify-content: center;

               p {
                    max-width: 440px;
               }
          }
          .image {
               padding-left: 30px;
               @media (max-width: 1023px) {
                    padding-left: 0;
               }
               width: 100%;
               img {
                    height: 100%;
                    max-height: 500px;
                    width: auto;
                    display: block;
                    margin: auto;

                    @media (max-width: 1023px) {
                         max-height: 260px;
                    }
               }
          }
     }
     .section2 {
          color: #89c184;
          font-size: 33px;

          padding: 120px 0 110px;

          @media (max-width: 1023px) {
               padding: 60px 0 120px;
               font-size: 28px;
          }

          font-weight: 500;
     }

     .section3 {
          @media (max-width: 1023px) {
               padding: 70px 0;
               font-size: 28px;
          }

          padding: 60px 0 40px;
          background-color: $secondary_bg;
          color: $tertiary;
          .section-title {
               color: $tertiary;
          }

          .columns {
               .column {
                    .service-item {
                         margin-bottom: 30px;
                         transition: transform 0.2s;
                         max-width: 600px;
                         a {
                              color: #fff;
                         }
                    }

                    .service-item:hover {
                         transform: scale(1.05);
                         @media screen and (max-width: 1023px) {
                              transform: scale(1);
                         }
                    }
               }
          }
     }
     .section4 {
          padding: 90px 0;

          .industry {
               .slide-container {
                    padding-right: 80px;
                    margin-bottom: 40px;

                    @media (max-width: 1023px) {
                         padding-right: 0;
                    }

                    img {
                         width: 100%;
                    }

                    .image-container {
                         position: relative;
                         span.button-container {
                              button {
                                   position: absolute;
                                   bottom: -15px;
                                   right: 20px;
                              }
                         }
                    }
               }
          }
          .viewprojectsbtn {
               cursor: pointer;
               button {
                    background: #89c184;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    // box-shadow: 0 8px 30px 10px rgba(100, 100, 100, 0.2);
                    // transition: box-shadow 0.6s;
               }
          }

          .viewprojectsbtn:hover {
               button {
                    box-shadow: none;
               }
          }

          @media (max-width: 1023px) {
               padding: 70px 0 40px;
          }
          .section-header {
               max-width: 850px;
               margin-bottom: 0;
          }
     }
     .section4_1 {
          .news-container {
               margin-top: -40px;
               margin-bottom: 120px;
               @media (max-width: 1023px) {
                    margin-top: 30px;
                    margin-bottom: 50px;
               }
               .item {
                    width: auto;
                    position: relative;
                    img {
                         width: 100%;
                         height: auto;
                    }
                    .overlay {
                         cursor: pointer;
                         transition: 0.5s;
                         position: absolute;
                         top: 0;
                         left: 0;

                         background-color: rgba(0, 0, 0, 0.7);
                         height: calc(100% - 6px);
                         width: 100%;

                         .overlay-text {
                              padding: 40px;
                              @media (max-width: 1023px) {
                                   padding: 30px;
                              }
                              overflow-y: auto;
                              width: 100%;
                              height: 100%;
                              position: relative;

                              .title {
                                   font-weight: 500;

                                   color: white;
                                   font-size: 27px;
                              }
                              .icon {
                                   width: 50px;

                                   position: relative;
                                   top: 2px;
                                   left: 8px;
                              }
                         }

                         .content {
                              .date {
                                   font-size: 16.5px;
                              }

                              p {
                                   color: #fff;
                              }
                         }
                    }
               }

               .item.news {
                    .overlay {
                         .overlay-text {
                              .title {
                                   position: absolute;
                                   bottom: 60px;
                                   left: 60px;
                                   font-weight: 500;
                                   color: white;
                                   font-size: 27px;
                              }
                              .icon {
                              }
                         }
                    }
               }

               .item.whatsnew {
                    .overlay {
                         cursor: default;
                         .overlay-text {
                              .title {
                                   font-weight: 500;
                                   color: white;
                                   font-size: 27px;
                              }
                              .icon {
                                   display: none;
                              }
                         }
                    }
               }
               .overlay:hover {
                    background-color: rgba(0, 0, 0, 0.8);
               }
          }
     }

     .section5 {
          margin-top: 0px;
          @media (max-width: 1023px) {
               margin-top: 120px;
          }
          .hoopernavigation {
               position: relative;
               top: -10px;

               @media (max-width: 1023px) {
                    top: 30px;
               }
          }

          .testimonials {
               .name {
                    color: #89c184;
                    font-size: 19px;
                    margin-top: 20px;
                    font-weight: 500;
                    display: inline;
               }
               .position {
                    // margin-top: 2px;
                    padding-left: 3px;
                    display: inline;
               }
               .testimonial {
                    // background-color: red;
                    background-image: url("../assets/images/backslash.png");
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: 40px auto;

                    font-size: 19px !important;

                    margin-top: 30px;
                    margin-right: 80px;

                    min-height: 235px;

                    padding-top: 60px;
                    padding-bottom: 20px;
               }
          }
          .testimonials.is-hidden-desktop {
               .testimonial {
                    padding-bottom: 35px;
               }
          }
     }

     .section6 {
          margin: 80px auto 110px auto;
          @media (max-width: 1023px) {
               margin-top: 80px;
          }
          .certifications {
               padding-right: 80px;
               @media (max-width: 1023px) {
                    padding-right: 0px;
               }
               .section-header {
                    max-width: 550px;
               }
          }

          img {
               width: 100%;
               max-width: 110px;
               padding: 0;

               @media (max-width: 1023px) {
                    padding-top: 30px;
               }
               height: auto;
               filter: opacity(50%);
          }
     }
</style>

<style lang="scss">
     // .scrollsnap {
     //      scroll-snap-type: y proximity;
     // }
</style>
